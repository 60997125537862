












































































































import { SfTabs, SfButton } from '@storefront-ui/vue';
import {
  defineComponent,
  useRouter,
  useContext,
  ref,
  computed,
  useFetch,
} from '@nuxtjs/composition-api';
import { useUser, useAddresses } from '@wemade-vsf/customer';
import { useCompany } from '@wemade-vsf/company';
import userAddressesGetters from '@wemade-vsf/customer/lib/runtime/getters/userAddressesGetters';
import UserAddressDetails from 'components/theme/UserAddressDetails.vue';
import { CustomerAddress, Country } from '@wemade-vsf/magento-api';
import SkeletonLoader from 'components/theme/SkeletonLoader.vue';
import { useCountrySearch } from '@wemade-vsf/composables';

export default defineComponent({
  name: 'AddressesDetails',
  components: {
    SfTabs,
    SfButton,
    UserAddressDetails,
    SkeletonLoader,
  },
  setup() {
    const context = useContext();
    const router = useRouter();
    const { user, isCompanyCustomer, load: loadUser } = useUser();
    const { company, load: loadCompany } = useCompany();

    const userAddresses = ref<CustomerAddress[]>([]);
    const countries = ref<Country[]>([]);
    const { load, remove } = useAddresses();
    const { load: loadCountries } = useCountrySearch();

    console.log(isCompanyCustomer.value)
    const isCompanyAdmin = computed(() => isCompanyCustomer.value && company.value?.super_user?.email === user.value?.email)
    const canManageAddresses = computed(() => isCompanyCustomer.value ? isCompanyAdmin.value : true)
    const { canAddAddresses } = context.app.$wm.company;
    const canAddAddress = computed(() => isCompanyCustomer.value ? isCompanyAdmin.value && canAddAddresses : true);

    const { fetch } = useFetch(async () => {
      if (!user.value) await loadUser()
      if (isCompanyCustomer.value && !company.value) {
        await loadCompany()
        if (company.value?.super_user) {
          userAddresses.value = userAddressesGetters.getAddresses(company.value.super_user.addresses)
        }
      } else {
        const addressesResponse = await load()
        userAddresses.value = userAddressesGetters.getAddresses(addressesResponse)
      }

      countries.value = await loadCountries();      
    });

    const goToCreateAddressPage = () => router.push(
      context.localeRoute({ name: 'customer-addresses-details-new' }),
    );

    const goToEditAddressPage = (addressId: number) => router.push(
      context.localeRoute({ name: 'customer-addresses-details-edit', params: { addressId: String(addressId) } }),
    );

    const removeAddress = async (address: CustomerAddress) => {
      await remove({ address });
      fetch();
    };

    const addressesWithCountryName = computed(() => userAddresses.value.map((address) => ({
      ...address,
      countryName: countries.value
        .find(({ id }) => id === address.country_code)
        ?.full_name_locale
        ?? address.country_code,
    })));

    const canChangeAddress = (address) => {
      if (!isCompanyCustomer.value) return true
      if (!canManageAddresses.value) return false

      const { canUpdateDefaultBilling, canUpdateDefaultShipping, canEditAddresses } = context.app.$wm.company
      if (!canEditAddresses) return false
      if (address?.default_billing && !canUpdateDefaultBilling) return false
      if (address?.default_shipping && !canUpdateDefaultShipping) return false
      return true
    }

    return {
      countries,
      userAddresses,
      addressesWithCountryName,
      goToCreateAddressPage,
      goToEditAddressPage,
      removeAddress,
      getId: userAddressesGetters.getId,
      isDefault: userAddressesGetters.isDefault,
      isCompanyCustomer,
      isCompanyAdmin,
      canManageAddresses,
      canChangeAddress,
      canAddAddress,
      company
    };
  },
});
